import GetList from '../GetList';
import { Search } from '../Search';

async function GetOptions(data) {
  let returned;
  let dataParsed;
  let returnData = [];
  if (typeof data == 'object') {
    if (data.new) {
      dataParsed = data;
      returned = await Search(data, { unlimited: true });
    } else {
      return Object.values(data);
    }
  } else {
    dataParsed = data();
    returned = await GetList(dataParsed, { perpage: -1 });
  }

  returned.rows.forEach(row => {
    returnData.push({ key: row[dataParsed.id], value: row[dataParsed.nameField] });
  });

  return returnData;
}

export default GetOptions;
