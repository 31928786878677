const SharedData = require('./SharedData');

module.exports = class Showroom extends SharedData {
  constructor() {
    super({
      id: 'id',
      nameField: 'title',
      nameSingle: 'show',
      nameMultiple: 'shows',
      apiCallReturnSubtype: 'agenda_v2',
      options: ['view', 'download'],
      downloadLinkField: 'recording_path',
      search: {
        searchValues: {
          id: 'number',
          title: 'string'
        }
      },
      get: {
        apiReturnStructure: `{
          id
          title
          created_at
          endDateTime
          startDateTime
          recording_path
        }`
      }
    });

    this.inputConstants = {
      activeWebcamroom: 1,
      type: ['show', 'radio']
    };

    this.defaultOrder = 'startDateTime desc';

    this.fields = [
      {
        name: 'ID',
        field: 'id',
        type: 'TextField',
        required: true,
        list: true,
        edit: false
      },
      {
        name: 'Show',
        field: 'title',
        type: 'TextField',
        required: true,
        list: true,
        edit: false
      },
      {
        name: 'Begint op',
        field: 'startDateTime',
        type: 'TextField',
        required: true,
        list: true,
        edit: false
      },
      {
        name: 'Aangemaakt op',
        field: 'created_at',
        type: 'TextField',
        required: true,
        list: true,
        edit: false
      },
      {
        field: 'recording_path',
        type: 'M3U8Player',
        view: true,
        required: true
      }
    ];

    this.filters = [
      {
        name: 'Begint na',
        field: 'startDateTime',
        type: 'DateTime'
      },
      {
        name: 'Eindigt voor',
        field: 'endDateTime',
        type: 'DateTime'
      }
    ];

    return this;
  }
};
