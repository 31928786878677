import { API, graphqlOperation } from 'aws-amplify';
async function GetList(data, info) {
  let sendfields = [];
  if (info) {
    for (let variable in info) {
      sendfields.push(variable + ': ' + JSON.stringify(info[variable]));
    }
  }

  const ret = {};
  ret.rows = [];
  ret.count = 0;

  if (sendfields['perpage']) {
    ret.perpage = sendfields['perpage'];
  }
  if (sendfields['page']) {
    ret.perpage = sendfields['page'];
  }
  if (sendfields['id']) {
    ret.id = sendfields['id'];
  }

  const fields = [];
  const listFields = [];
  fields.push(data.id);

  // console.log("testing1",info)

  data.fields.forEach(field => {
    // console.log("testing2",info.id)
    //console.log("testing",field.field,field.list,field.edit,info.id,"info.id != undefined",info.id != undefined,"field.edit !== false",field.edit !== false);
    if (field.list == true || (info['id'] != undefined && field.edit !== false)) {
      //  console.log("testing to get ",field.field);
      fields.push(field.field);
      listFields.push(field.field);
    }
  });

  let query = `query MyQuery {
      ${data.getApi} (${sendfields.join(' \n')}) {  
      count
        perpage
        page
        data {
          ${fields.join('\n\r')}
        }
      }
    }
    `;
  console.log('query', query);

  await API.graphql(graphqlOperation(query, {}))
    .then(res => {
      console.log('graphql res', res);
      ret.page = res.data[data.getApi].page;
      ret.count = res.data[data.getApi].count;
      ret.perpage = res.data[data.getApi].perpage;

      res.data[data.getApi].data.forEach(record => {
        let item = {};
        listFields.forEach(field => {
          item[field] = record[field];
        });
        item[data.id] = record[data.id];
        ret.rows.push(item);
      });
    })
    .catch(error => {
      console.log('GRAPHQL ERROR', error);
      alert(JSON.stringify(error));
    });

  // console.log("data returned ",ret);

  return ret;
}

export default GetList;
