module.exports.WebcamClowns = () => {
  return {
    getApi: 'getWebcamClowns',
    deleteApi: '',
    id: 'id',
    nameField: 'user_name',
    subidname: 'display_name',
    options: ['edit', 'delete', 'insert'],
    nameSingle: 'clown',
    nameMultiple: 'clowns',

    fields: [
      {
        name: 'ID',
        field: 'id',
        type: 'Display',
        required: true,
        list: true,
        edit: false
      },
      {
        name: 'Name',
        field: 'display_name',
        type: 'TextInput',
        required: true,
        list: true
      },
      {
        name: 'Gebruikersnaam',
        field: 'user_name',
        type: 'TextInput',
        required: true,
        list: true
      }
    ]
  };
};
