import { API, graphqlOperation } from 'aws-amplify';

/**
 * Call GraphQL mutation for the object passed to handle the deletion of its records
 * @param {Object} data - Data Object
 * @param {Array} ids - Array of ids
 * @returns {Object}
 */
async function DeleteIds(data, ids, deleteRepeating) {
  let mutation;
  let mutationResults;

  if (data.new) {
    const idValue = `[${ids.toString()}]`;

    const inputStructure = data.delete.apiInput.replace(/idValue/, idValue);

    mutation = `mutation ${data.delete.apiCall}Mutation {
      ${data.delete.apiCall}(${inputStructure}${deleteRepeating ? ' deleteAll: true' : ''}) ${data.delete.apiReturnStructure}
    }`;

    console.log('delete', mutation);

    const res = await API.graphql(graphqlOperation(mutation, {})).catch(error => {
      console.log('GRAPHQL ERROR', error);
      alert('oeps! ', error.message);
    });

    console.log('delete complete', res);

    if (res.data && res.data[data.delete.apiCall]) {
      mutationResults = res.data[data.delete.apiCall];
    }

    return mutationResults;
  } else {
    const ret = {};

    let deleteApiCall = data.deleteApi;

    let query = `
      mutation ${deleteApiCall}Mutation {
      ${deleteApiCall}(ids: "${ids.join(',')}") {
          result
        }
      }`;
    console.log('delete! ', query);

    await API.graphql(graphqlOperation(query, {}))
      .then(res => {
        console.log('delete complete', res);
      })
      .catch(error => {
        console.log('GRAPHQL ERROR', error);
        alert('oeps! ', error);
      });
    return ret;
  }
}

export default DeleteIds;
