const { escape } = require('sqlstring');
const { API, graphqlOperation } = require('aws-amplify');

/**
 * Build the graphql call required for searching items of the specific class being searched for
 * @param {Object} data - An object instantiated from the base class with the correct configuration for searching
 * @param {Object} input - An object with all the necessary search parameters, including the `searchForOne` attribute which will toggle the search to look specifically for a record based on the id passed in the `search` attribute of `input`
 * @returns {Object} The results of the graphql call
 */
module.exports.Search = async (data, input) => {
  let queryInputs = {
    searchValues: '',
    filterValues: '',
    queryLimitValues: '',
    unlimitedValue: false
  };

  if (input.searchForOne) {
    if (input.deepSearchId && input.deepSearchId !== '') {
      queryInputs.searchValues = `${input.deepSearchId}: ${input.search}`;
    } else {
      queryInputs.searchValues = `${data.id}: ${input.search}`;
    }

    let queryLimitString = ``;
    for (const queryLimitKey in input.queryLimits) {
      if (input.queryLimits[queryLimitKey] === '') {
        queryLimitString += `${queryLimitKey}: "" `;
      } else {
        queryLimitString += `${queryLimitKey}: ${escape(input.queryLimits[queryLimitKey]).replace(/\'/g, '"')} `;
      }
    }

    queryInputs.queryLimitValues = queryLimitString;
    console.log('searchForOne', queryInputs);
  } else {
    for (const inputKey in input) {
      switch (inputKey) {
        case 'queryLimits':
          let queryLimitString = ``;
          for (const queryLimitKey in input[inputKey]) {
            if (input[inputKey][queryLimitKey] === '') {
              queryLimitString += `${queryLimitKey}: "" `;
            } else {
              queryLimitString += `${queryLimitKey}: ${escape(input[inputKey][queryLimitKey]).replace(/\'/g, '"')} `;
            }
          }
          queryInputs.queryLimitValues = queryLimitString;
          break;
        case 'search':
          let querySearchString = ``;
          // make sure string is not an entirely empty string
          if (!/^\s*$/g.test(input[inputKey])) {
            // loop through the search values on the class
            for (const searchValue in data.searchValues) {
              // if graphql expects a string wrap it in double quotes, otherwise if it expects a number attempt a typecast
              if (data.searchValues[searchValue] === 'string') {
                querySearchString += `${searchValue}: "${input[inputKey]}" `;
              } else if (data.searchValues[searchValue] === 'number' && !Number.isNaN(Number(input[inputKey]))) {
                querySearchString += `${searchValue}: ${input[inputKey]} `;
              }
            }
          }
          queryInputs.searchValues = querySearchString;
          break;
        case 'filters':
          // console.log('filters', input[inputKey]);
          let filterSearchString = ``;
          for (const filterKey in input[inputKey]) {
            if (input[inputKey][filterKey] !== '') {
              filterSearchString += `${filterKey}: ${escape(input[inputKey][filterKey]).replace(/\'/g, '"')} `;
            }
          }
          queryInputs.filterValues = filterSearchString;
          break;
        case 'unlimited':
          queryInputs.unlimitedValue = input.unlimited;
          break;
      }
    }
  }

  if (!input.skipConstants) {
    for (const constantKey in data.inputConstants) {
      queryInputs.filterValues += `${constantKey}: ${formatInputConstants(data.inputConstants[constantKey])} `;
    }
  }

  for (const placeholderKey in queryInputs) {
    console.log('replacting', placeholderKey);
    const placeholderRegex = new RegExp(placeholderKey, 'g');
    data.get.apiInput = data.get.apiInput.replace(placeholderRegex, queryInputs[placeholderKey].toString());
  }

  if (data.operand) {
    data.get.apiInput += ` operand: ${data.operand}`;
  }

  const query = `query ${data.get.apiCall}Query {
    ${data.get.apiCall} (${data.get.apiInput}) {
      ${data.get.apiReturnStructure}
    }
  }`;

  console.log(query);

  const searchResults = {
    rows: [],
    queryResultInfo: {
      page: 0,
      count: 0,
      order: '',
      perpage: 10
    }
  };

  const listFields = [];

  data.fields.forEach(field => {
    if (field.list === true || field.edit === true || field.create === true || field.required === true) {
      listFields.push(field.field);
    }
  });

  if (data.options.indexOf('deep') !== -1) {
    data.subIdList.forEach(subId => {
      listFields.push(subId.field);
    });
  }

  const res = await API.graphql(graphqlOperation(query, {})).catch(error => {
    console.log('GRAPHQL ERROR', error);
    alert(JSON.stringify(error));
  });

  const apiCall = data.get.apiCall;
  const apiCallReturnSubtype = data.get.apiCallReturnSubtype;

  searchResults.queryResultInfo.page = res.data[apiCall].queryResultInfo.page;
  searchResults.queryResultInfo.count = res.data[apiCall].queryResultInfo.count;
  searchResults.queryResultInfo.perpage = res.data[apiCall].queryResultInfo.perpage;

  res.data[apiCall][apiCallReturnSubtype].forEach(record => {
    let item = {};

    listFields.forEach(field => {
      item[field] = record[field];
    });

    item[data.id] = record[data.id];

    searchResults.rows.push(item);
  });

  console.log('searchResults', searchResults);

  return searchResults;
};

function formatInputConstants(constant) {
  if (typeof constant === 'number') {
    return `${constant}`;
  } else if (Array.isArray(constant)) {
    let data = `[`;
    constant.forEach(value => (data += formatInputConstants(value)));
    data += ']';
    return data;
  } else {
    return `"${constant}"`;
  }
}
