import EditListForm from './EditListForm';
import EditListList from './EditListList';
import React, { useEffect, useState } from 'react';
import FormManager from '../../FormGenerator/FormManager';
import { FormContext } from '../../../contexts/FormContext';
import TextField from '../../FormGenerator/Fields/TextField';
import NoClownsInput from '../../input/NoClownsInput';

const EditList = props => {
  const [formManager, setFormManager] = useState(new FormManager(props.data.data()));

  return (
    <>
      <FormContext.Provider value={formManager} value2={props}>
        {props.data.name === 'Agenda' ? (
          <NoClownsInput></NoClownsInput>
        ) :''}
        <EditListList {...props}></EditListList>
        <EditListForm {...props}></EditListForm>
      </FormContext.Provider>
    </>
  );
};

export default EditList;
