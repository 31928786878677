const User = require('./User');
const SharedData = require('./SharedData');
const Categories = require('./Categories');

module.exports = class Video extends SharedData {
  constructor(options = {}) {
    super({
      id: 'id',
      nameField: 'id',
      nameSingle: 'video',
      nameMultiple: `video's`,
      apiCallReturnSubtype: 'videos',
      options: ['edit', 'delete', 'insert'],
      search: {
        searchValues: {
          title: 'string',
          description: 'string'
        }
      },
      get: {
        apiReturnStructure: `{
          id
          title
          description
          image
          file_original
          status
          tags
          views
          likes
          online_at
          offline_at
          liked
          viewed
          clowns
          categories
          created_at
          updated_at
        }`
      },
      update: {
        apiCall: 'CMSInsertUpdateVideos',
        apiCallReturnSubtype: null,
        apiInput: `id: idValue, input: { inputStructure }`,
        apiReturnStructure: `{
          extra
          id
          result
          token
        }`
      },
      delete: {
        apiCall: 'deleteVideos'
      }
    });

    this.defaultOrder = 'created_at asc';

    this.modifyOnInsertUpdate = {
      tags: this.modifyTags,
      clowns: this.modifyClowns,
      image: this.removeBucketPath,
      categories: this.modifyCategories,
      file_original: this.removeBucketPath
    };

    this.fields = [
      {
        name: 'ID',
        field: 'id',
        type: 'TextField',
        required: false,
        list: true,
        edit: false
      },
      {
        name: 'Titel',
        field: 'title',
        type: 'TextField',
        required: true,
        list: true,
        edit: true
      },
      {
        name: 'Status',
        field: 'status',
        type: 'TextField',
        required: true,
        list: true,
        edit: false
      },
      {
        name: 'Aangemaakt op',
        field: 'created_at',
        type: 'TextField',
        list: true,
        edit: false
      },
      {
        edit: true,
        required: true,
        name: 'Omschrijving',
        field: 'description',
        type: 'TextField'
      },
      {
        name: 'Online-datum',
        field: 'online_at',
        type: 'DateTime',
        required: true,
        list: true,
        edit: true
      },
      {
        name: 'Offline-datum',
        field: 'offline_at',
        type: 'DateTime',
        required: true,
        list: true,
        edit: true
      },
      {
        name: 'Video',
        field: 'file_original',
        type: 'VideoUpload',
        required: true,
        list: false,
        edit: true,
        apiType: 'CMSVideo',
        uploadFolder: 'UploadedVideos'
      },
      {
        name: 'Afbeelding',
        field: 'image',
        type: 'ImageUpload',
        required: false,
        list: false,
        edit: true,
        apiType: 'CMSImage',
        uploadFolder: this.createImageUploadFolder(),
        resize: {
          width: 320,
          height: 320,
          type: 'fill',
          quality: 0.85,
          bgcolor: '#ffffff'
        }
      },
      {
        name: 'Categorien',
        field: 'categories',
        type: 'SelectTransfer',
        options: new Categories(),
        required: false,
        list: false,
        edit: true,
        keyType: 'int'
      },
      {
        name: 'Tags',
        field: 'tags',
        type: 'Tags',
        edit: true
      },
      {
        name: 'Clowns',
        field: 'clowns',
        type: 'SelectTransfer',
        options: new User({ constants: { type: 'clown' } }),
        list: false,
        edit: true,
        keyType: 'int'
      }
    ];

    return this;
  }

  /**
   * Dynamically create the correct bucket location for uploads
   * @returns {String} S3 bucket location based on current date
   */
  createImageUploadFolder = () => {
    return `${process.env.GATSBY_DEPLOY_STAGE}v2/VideoImages`;
  };

  modifyTags = data => {
    let values = [];

    for (let i = 0; i < data.length; i++) {
      const tag = data[i];
      values.push(`"${tag}"`);
    }

    return values;
  };

  modifyCategories = data => {
    if (!data) {
      return [];
    } else {
      return data;
    }
  };

  modifyClowns = data => {
    if (!data) {
      return [];
    } else {
      return data;
    }
  };

  removeBucketPath = data => {
    if (data.indexOf('version2.appstorage.cliniclowns.nl') !== -1) {
      return data.split('version2.appstorage.cliniclowns.nl')[1];
    } else return data;
  };
};
