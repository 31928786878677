import { v4 } from 'uuid';
import axios from 'axios';
import styled from 'styled-components/macro';
import GetList from '../../../utils/GetList';
import { spacing } from '@material-ui/system';
import { Search } from '../../../utils/Search';
import capitalize from '../../../utils/capitalize';
import React, { useEffect, useContext } from 'react';
import convertMedia from '../../../utils/convertMedia';
import { FormContext } from '../../../contexts/FormContext';
import FormGenerator from '../../FormGenerator/FormGenerator';
import InsertUpdateRecord from '../../../utils/InsertUpdateRecord';
import { Button, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Card as MuiCard, Paper as MuiPaper } from '@material-ui/core';
import { useSelector } from 'react-redux';

const queryString = require('query-string');

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Image = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
`;

const Saving = styled.div`
  padding-right: 20px;
`;

const EditListForm = props => {
  const res = React.useRef('');
  const context = useContext(FormContext);
  const user = useSelector(state => state.authReducer.user);
  const groups = user?.signInUserSession.accessToken.payload['cognito:groups'] ?? [];
  const downloadUrl = React.useRef('');
  const [open, setOpen] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [maxWidth, setMaxWidth] = React.useState('xl');
  const [fullWidth, setFullWidth] = React.useState(true);
  const [downloading, setDownloading] = React.useState(false);

  const dataObject = new props.data.data();

  const handleClose = () => {
    props.OpenRecord(null);
    context.setRecordType(null);
  };

  function parseChange(e) {
    if (e.detail.field == 'type') {
      context.setRecordType(e.detail.value);
    }
  }

  useEffect(() => {
    context.events.addEventListener('change', parseChange);
    return () => {
      context.events.removeEventListener('change', parseChange);
    };
  }, []);

  useEffect(async () => {
    setLoaded(false);
    setSaving(false);
    if (props.editID != null) {
      emptyForm();
      setOpen(true);
      if (props.editID != 0) {
        await loadItem(props.editID);
      } else {
        setLoaded(true);
      }
    } else {
      setOpen(false);
    }
  }, [props.editID]);

  function emptyForm() {
    context.clear();
  }

  async function loadItem(id) {
    let data;
    if (dataObject.new) {
      data = await Search(dataObject, {
        queryLimits: {
          page: 0,
          perpage: 1
        },
        search: id,
        searchForOne: true
      });
    } else {
      data = await GetList(props.data.data(), {
        page: 0,
        perpage: 1,
        id: id
      });
    }
    if (data.rows && data.rows.length > 0) {
      let row = data.rows[0];
      context.setData(row);
      if (dataObject.multiType && row[dataObject.multiTypeId]) {
        context.setRecordType(row.type);
      }
      setLoaded(true);
    }
  }

  const handleSave = async () => {
    setLoaded(false);
    if (dataObject.options.indexOf('edit') !== -1 && context.checkForm()) {
      setSaving(true);
      let id = null;
      if (props.editID != 0) {
        id = props.editID;
      } else if (window.location.href.indexOf('subId') !== -1 && dataObject.nameField === 'user_notes') {
        const queryParams = queryString.parse(window.location.href.split('?')[1]);

        context.setFieldValue('user_id', [queryParams.subId], true);
      }
      const update = await InsertUpdateRecord(dataObject, context.getFields(), id, context.recordType).catch(err => {
        setSaving(false);
      });

      if (update) {
        props.Refresh();
        setSaving(false);
        handleClose();
      }
    }
    setLoaded(true);
  };

  const handleDownload = async () => {
    try {
      setDownloading(true);
      const fileId = context.row[dataObject.download.downloadLinkField].split('/')[4].split('?')[0];
      if (downloadUrl.current === '') {
        res.current = await convertMedia(fileId);
      }
      downloadUrl.current = res.current?.data?.convertMedia?.url;
      const a = document.createElement('a');
      const media = await axios.get(res.current?.data?.convertMedia?.url, { responseType: 'blob' });
      a.href = URL.createObjectURL(media.data);
      a.type = dataObject.download.fileType;
      a.download = `${v4()}.${dataObject.download.fileType}`;
      a.click();
      setDownloading(false);
      res.current = '';
      downloadUrl.current = '';
    } catch (err) {
      console.log('CONVERTING MEDIA ERROR', err);
      setTimeout(async () => await handleDownload(), 5000);
    }
  };

  return (
    <>
      <Card mb={6}>
        <CardContent>
          <Paper mt={4}>
            <React.Fragment>
              <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={open} onClose={handleClose} aria-labelledby="max-width-dialog-title">
                <DialogTitle id="max-width-dialog-title">
                  {capitalize(dataObject.nameSingle)}
                  <Image
                    onClick={handleClose}
                    src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGhlaWdodD0iNTEycHgiIGlkPSJMYXllcl8xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgd2lkdGg9IjUxMnB4IiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48cGF0aCBkPSJNNDQzLjYsMzg3LjFMMzEyLjQsMjU1LjRsMTMxLjUtMTMwYzUuNC01LjQsNS40LTE0LjIsMC0xOS42bC0zNy40LTM3LjZjLTIuNi0yLjYtNi4xLTQtOS44LTRjLTMuNywwLTcuMiwxLjUtOS44LDQgIEwyNTYsMTk3LjhMMTI0LjksNjguM2MtMi42LTIuNi02LjEtNC05LjgtNGMtMy43LDAtNy4yLDEuNS05LjgsNEw2OCwxMDUuOWMtNS40LDUuNC01LjQsMTQuMiwwLDE5LjZsMTMxLjUsMTMwTDY4LjQsMzg3LjEgIGMtMi42LDIuNi00LjEsNi4xLTQuMSw5LjhjMCwzLjcsMS40LDcuMiw0LjEsOS44bDM3LjQsMzcuNmMyLjcsMi43LDYuMiw0LjEsOS44LDQuMWMzLjUsMCw3LjEtMS4zLDkuOC00LjFMMjU2LDMxMy4xbDEzMC43LDEzMS4xICBjMi43LDIuNyw2LjIsNC4xLDkuOCw0LjFjMy41LDAsNy4xLTEuMyw5LjgtNC4xbDM3LjQtMzcuNmMyLjYtMi42LDQuMS02LjEsNC4xLTkuOEM0NDcuNywzOTMuMiw0NDYuMiwzODkuNyw0NDMuNiwzODcuMXoiLz48L3N2Zz4="
                  ></Image>
                </DialogTitle>
                <DialogContent>
                  <FormGenerator {...props}></FormGenerator>
                </DialogContent>
                <DialogActions>
                  {loaded && dataObject.options.indexOf('download') === -1 ? (
                    <Button onClick={handleSave} color="primary">
                      Opslaan
                    </Button>
                  ) : (
                    loaded &&
                    !downloading &&
                    groups.includes('admins') && (
                      <Button disabled={downloading} onClick={handleDownload} color="primary">
                        Download
                      </Button>
                    )
                  )}
                  {downloading && <Saving>Wacht even op je download...</Saving>}
                  {saving && <Saving>Opslaan...</Saving>}
                </DialogActions>
              </Dialog>
            </React.Fragment>
          </Paper>
        </CardContent>
      </Card>
    </>
  );
};

export default EditListForm;
