import User from './Data/User';
import Notes from './Data/Notes';
import Alarm from './Data/Alarm';
import Chatroom from './Data/Chatroom';
import Cussword from './Data/Cussword';
import Video from '../config/Data/Video';
import Webcamroom from './Data/Webcamroom';
import UserExport from './Data/UserExport';
import Agenda from '../config/Data/Agenda';
import ChatMessage from './Data/ChatMessage';
import EditList from '../components/pagetemplates/EditList/EditList';

import {
  ChatBubbleOutline,
  DnsOutlined,
  CalendarTodayOutlined,
  VideoCallOutlined,
  BlockOutlined,
  CallOutlined,
  PersonOutlineOutlined,
  AnnouncementOutlined
} from '@material-ui/icons';
import Showroom from './Data/Showroom';
import ShowChatroom from './Data/ShowChatroom';

export const s3Path = 'https://s3.eu-central-1.amazonaws.com/version2.appstorage.cliniclowns.nl';

const cmsConfig = {
  name: 'CliniClowns CMS',
  loginType: 'email',
  Structure: [
    {
      icon: PersonOutlineOutlined,
      name: 'Gebruikers',
      sidebarDisplay: true,
      items: [
        {
          data: User,
          name: 'Gebruikers',
          component: EditList,
          path: '/users'
        },
        {
          data: Notes,
          name: 'Opmerkingen',
          component: EditList,
          path: '/opmerkingen',
          hideFromSidebar: true
        }
      ]
    },
    {
      icon: ChatBubbleOutline,
      name: 'Chats',
      sidebarDisplay: true,
      items: [
        {
          data: Chatroom,
          name: 'Chats',
          component: EditList,
          path: '/chatrooms'
        },
        {
          data: ShowChatroom,
          name: 'Show Chats',
          component: EditList,
          path: '/show-chatrooms'
        },
        {
          data: ChatMessage,
          name: 'Berichten',
          component: EditList,
          path: '/berichten',
          hideFromSidebar: true
        }
      ]
    },
    {
      icon: CallOutlined,
      name: 'Videobeelden',
      sidebarDisplay: true,
      items: [
        {
          data: Webcamroom,
          name: 'Videobeelden',
          component: EditList,
          path: '/webcamrooms',
          auth: ['clowns', 'specialists']
        },
        {
          data: Showroom,
          name: 'Shows',
          component: EditList,
          path: '/showrooms',
          auth: ['clowns', 'specialists']
        }
      ]
    },
    {
      icon: CalendarTodayOutlined,
      sidebarDisplay: true,
      name: 'Planning',
      items: [
        {
          name: 'Agenda',
          path: '/agenda',
          component: EditList,
          data: Agenda
        }
      ]
    },
    {
      icon: VideoCallOutlined,
      sidebarDisplay: true,
      name: 'Content',
      items: [
        {
          name: `Video's`,
          path: '/videos',
          component: EditList,
          data: Video
        }
      ]
    },
    {
      icon: BlockOutlined,
      sidebarDisplay: true,
      name: 'Scheldwoorden',
      items: [
        {
          name: 'Scheldwoorden',
          path: '/cusswords',
          component: EditList,
          data: Cussword
        }
      ]
    },
    {
      icon: AnnouncementOutlined,
      sidebarDisplay: true,
      name: 'Meldingen',
      items: [
        {
          name: 'Meldingen',
          path: '/meldingen',
          component: EditList,
          data: Alarm
        }
      ]
    },
    {
      icon: DnsOutlined,
      sidebarDisplay: true,
      name: 'Exporteren',
      items: [
        {
          name: 'Exporteer gebruikers',
          path: '/users-export',
          component: EditList,
          data: UserExport
        }
      ]
    }
  ]
};

export default cmsConfig;
